export default {
  get: function(){
    return {
      id: null,
      name: null,
      classesAmount: null,
      description: null,
      icon: null,
      progress: 0,
      certified: null,
      totalExams:null,
      uniqid: null,
      credits: null,
      isBootcamp: null,
      exams:{
        total: null,
        passed: null,
        certified: null
      },
      startedResource: {},
      attributes:{},
      channel:{},
      api:{
        get:{
          fetching: false,
          error: false,
          values: {},
        },
        put: {

        },
        post:{

        },
        delete:{

        }
      }
    }
  }
}
