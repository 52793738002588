import initialState from './state.js'

const RESET_STATE = (state) => {
  let iState = initialState.get();
  //Preserve fetching state
  iState.api.get.fetching = state.api.get.fetching;
  Object.assign(state, iState);
}

const FETCHING = (state, value) => {
  state.api.get.fetching = !!value;
}

const FETCH_UPDATED = (state, data) => {
  let d = data.data;

  state.api.get.values = d;
  state.id = d.course.id;
  state.name = d.course.name;
  state.description = d.course.description;
  state.icon = d.course.icon;
  state.progress = d.course.progress;
  state.certified = d.course.certified;
  state.uniqid = d.course.uniqid;
  state.credits = d.course.credits;
  state.isBootcamp = d.course.isBootcamp;

  state.attributes = d.attributes;

  state.exams.total = d.exams.total;
  state.exams.passed = d.exams.passed;
  state.exams.certified = d.exams.certified;

  state.startedResource = d.resource;
};

export default {
  FETCHING,
  RESET_STATE,
  FETCH_UPDATED,
};
