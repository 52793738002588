import MarkdownIt from 'markdown-it';

export default {
  install: function(Vue) {
    const md = new MarkdownIt({
      linkify: false,
      breaks: false,
      html: false,
    });

    const defaultRender = md.renderer.rules.link_open || function (tokens, idx, options, env, self) {
      return self.renderToken(tokens, idx, options);
    };

    md.renderer.rules.link_open = function (tokens, idx, options, env, self) {
      tokens[idx].attrSet('target', '_blank');
      return defaultRender(tokens, idx, options, env, self);
    };

    Vue.prototype.$md = function(text) {
      return md.render(text);
    }
  }
}
