

/**
 * @deprecated
 * @param state
 * @returns {*}
 */
const get = state => state.api.get.values;

const fetching = state => state.api.get.fetching;
const id = state => state.id;
const name = state => state.name;
const description = state => state.description;
const totalExams = state => state.exams.total;
const passedExams = state => state.exams.passed;
const isCertified = state => state.exams.certified;
const uniqid = state => state.uniqid;
const classesAmount = state => state.classesAmount;
const progress = state => state.progress;
const icon = state => state.icon;
const attributes = state => state.attributes;
const credits = state => state.credits;
const isBootcamp = state => state.isBootcamp;
const startedResource = state => state.startedResource;

export default {
  get,
  fetching,
  id,
  totalExams,
  name,
  description,
  uniqid,
  classesAmount,
  isCertified,
  passedExams,
  progress,
  icon,
  attributes,
  credits,
  isBootcamp,
  startedResource
}
